'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import { NetworkError } from '@/app/lcv/campaigns/ai-trusted-buddy/step/error';

export default function Error({
  error,
}: // reset,
{
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    window.onerror = function (message, url, line, column, error) {
      // eslint-disable-next-line no-console
      console.log(message, url, line, column, error);
    };
  }, [error]);
  const router = useRouter();

  const handleClick = () => {
    router.push('/');
  };

  return (
    <div className="fixed inset-0 overflow-hidden bg-black">
      <NetworkError label="กลับสู่หน้าหลัก" onClick={handleClick} />
    </div>
  );
}

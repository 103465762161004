import { Button } from '@/app/components/button';
import { Icon } from '@/app/components/icons';
import { IconError } from '@/app/components/icons/common-actions/icon-error';
import { AIFooter } from '@/app/lcv/campaigns/ai-trusted-buddy/shared/footer';
import { AIMain } from '@/app/lcv/campaigns/ai-trusted-buddy/shared/main';
import { AIWrapper } from '@/app/lcv/campaigns/ai-trusted-buddy/shared/wrapper';
import { StepProps } from '@/app/lcv/campaigns/ai-trusted-buddy/step/type';

interface NetworkErrorProps {
  label?: string;
  onClick?: () => void;
}
export const NetworkError = ({
  label = 'ลองอีกครั้ง',
  onClick,
}: NetworkErrorProps) => {
  const handleClick = () => {
    typeof onClick === 'function' && onClick();
  };
  return (
    <AIWrapper>
      <AIMain>
        <div className="flex h-full flex-col">
          <div className="my-auto pt-4">
            <Icon
              icon={<IconError />}
              size="3xl"
              className="mx-auto text-red-500"
            />
            <div className="mt-4 text-white">
              <h2 className="text-body-1 font-noto-bold">Network Error</h2>
              <p className="text-body-2">กรุณาลองใหม่อีกครั้ง</p>
            </div>
          </div>
        </div>
      </AIMain>

      <AIFooter>
        <Button size="sm" width="full" onClick={handleClick}>
          {label}
        </Button>
      </AIFooter>
    </AIWrapper>
  );
};

export const StepError = ({ updateStep }: StepProps) => {
  const handleClick = () => {
    typeof updateStep === 'function' &&
      updateStep({
        step: 'template',
      });
  };
  return <NetworkError onClick={handleClick} />;
};

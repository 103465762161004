import type { ReactNode } from 'react';

import { Container } from '@/app/components/layouts/container';
import { cn } from '@/app/utils/cn';

export interface AIMainProps {
  children: ReactNode;
  className?: string;
}

export const AIMain = ({ children, className }: AIMainProps) => {
  return (
    <main
      className={cn(
        'scroll-container scrollbar-hidden flex-1 text-center',
        className
      )}
    >
      <Container maxWidth="sm" className="h-full">
        {children}
      </Container>
    </main>
  );
};

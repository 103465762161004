import type { VariantProps } from 'class-variance-authority';
import Link from 'next/link';
import type {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';
import { ElementType } from 'react';

import { Icon, type IconType } from '@/app/components/icons';
import { cn } from '@/app/utils/cn';
import { cnva } from '@/app/utils/cnva';

type ButtonSizeType = VariantProps<typeof ButtonStyled>['size'];

const ICON_SIZE_SINGLE_BUTTON: Record<NonNullable<ButtonSizeType>, string> = {
  '2xs': 'size-4',
  xs: 'size-4',
  sm: 'size-6',
  md: 'size-6',
  lg: 'size-8',
  xl: 'size-10 md:size-12',
};

const ICON_SIZE_ICON_BUTTON = 'size-full';

const getIconSize = (size: ButtonSizeType, hasChildren: boolean): string =>
  hasChildren && size ? ICON_SIZE_SINGLE_BUTTON[size] : ICON_SIZE_ICON_BUTTON;

const renderIcon = (
  icon: IconType,
  size: ButtonSizeType,
  hasChildren: boolean
): ReactElement | null => {
  if (!icon) return null;

  return (
    <Icon
      icon={icon}
      className={cn(
        'pointer-events-none shrink-0',
        getIconSize(size, hasChildren)
      )}
    />
  );
};

const ButtonStyled = cnva(
  'rounded-2xs transition-form relative inline-flex cursor-pointer touch-manipulation items-center justify-center text-center outline-none focus-visible:outline-none',
  {
    variants: {
      color: {
        red: '',
        black: '',
        white: '',
      },
      size: {
        '2xs': '',
        xs: '',
        sm: '',
        md: '',
        lg: '',
        xl: '',
      },
      variant: {
        primary: '',
        secondary:
          'before:rounded-2xs before:transition-border before:pointer-events-none before:absolute before:inset-0 before:border-2',
        tertiary: '',
      },
      width: {
        auto: '',
        min: '',
        full: '',
      },
      shadow: {
        sm: 'shadow-sm',
        md: 'shadow-md',
        lg: 'shadow-lg',
        xl: 'shadow-xl',
        none: '',
      },
      disabled: {
        true: 'pointer-events-none disabled:text-gray-300',
        false: '',
      },
      hasChildren: {
        true: 'font-noto-bold gap-x-2',
        false: 'shrink-0',
      },
    },
    compoundVariants: [
      { hasChildren: false, size: '2xs', class: 'size-6 p-1' },
      { hasChildren: false, size: 'xs', class: 'size-6 p-1 md:size-8 md:p-2' },
      { hasChildren: false, size: 'sm', class: 'size-8 p-2 md:size-10' },
      { hasChildren: false, size: 'md', class: 'size-10 p-2 md:size-12' },
      {
        hasChildren: false,
        size: 'lg',
        class: 'size-12 p-2 md:size-14 md:p-3',
      },
      { hasChildren: false, size: 'xl', class: 'size-16 p-3 md:size-18' },
      { hasChildren: true, size: '2xs', class: 'text-button-2 h-6 px-2 py-1' },
      { hasChildren: true, size: 'xs', class: 'text-button-2 h-8 px-3 py-2' },
      { hasChildren: true, size: 'sm', class: 'text-button-1 h-10 px-3 py-2' },
      {
        hasChildren: true,
        size: 'md',
        class: 'text-button-1 h-10 px-3 py-2 md:h-12 md:px-5 md:py-3',
      },
      {
        hasChildren: true,
        size: 'lg',
        class: 'text-button-1 h-12 px-3 py-2 md:h-14 md:px-5 md:py-3',
      },
      {
        hasChildren: true,
        size: 'xl',
        class: 'text-button-1 h-16 px-5 py-3 md:h-18',
      },
      { hasChildren: true, width: 'full', class: 'w-full' },
      {
        hasChildren: true,
        width: 'min',
        size: ['md', 'lg', 'xl'],
        class: 'min-w-39 md:min-w-44',
      },
      { hasChildren: true, width: 'min', size: 'sm', class: 'min-w-39' },
      {
        color: 'red',
        variant: 'primary',
        class:
          'bg-red-500 text-white hover:bg-red-700 focus-visible:bg-red-700 focus-visible:text-white active:bg-red-700 active:text-white',
      },
      {
        color: 'red',
        variant: 'secondary',
        class:
          'text-red-500 before:border-red-500 hover:bg-red-500 hover:text-white focus-visible:bg-red-500 focus-visible:text-white active:bg-red-500 active:text-white',
      },
      {
        color: 'red',
        variant: 'tertiary',
        class:
          'text-red-500 hover:text-red-700 focus-visible:text-red-700 active:text-red-700',
      },
      {
        color: 'black',
        variant: 'primary',
        class:
          'bg-gray-800 text-white hover:bg-gray-600 focus-visible:bg-gray-600 focus-visible:text-white active:bg-gray-600 active:text-white',
      },
      {
        color: 'black',
        variant: 'secondary',
        class:
          'text-gray-800 before:border-gray-800 hover:bg-gray-800 hover:text-white focus-visible:bg-gray-800 focus-visible:text-white active:bg-gray-800 active:text-white',
      },
      {
        color: 'black',
        variant: 'tertiary',
        class:
          'text-gray-800 hover:text-red-500 focus-visible:text-red-500 active:text-red-500',
      },
      {
        color: 'white',
        variant: 'primary',
        class:
          'bg-white text-gray-800 hover:bg-gray-600 hover:text-white focus-visible:bg-gray-600 focus-visible:text-white active:bg-gray-600 active:text-white',
      },
      {
        color: 'white',
        variant: 'secondary',
        class:
          'text-white before:border-white hover:bg-white hover:text-gray-900 focus-visible:bg-white focus-visible:text-gray-900 active:bg-white active:text-gray-900',
      },
      {
        color: 'white',
        variant: 'tertiary',
        class:
          'text-white hover:text-red-500 focus-visible:text-red-500 active:text-red-500',
      },
      { disabled: true, variant: 'primary', class: 'disabled:bg-gray-50' },
      {
        disabled: true,
        variant: 'secondary',
        class: 'disabled:before:border-gray-300',
      },
    ],
    defaultVariants: {
      color: 'red',
      size: 'md',
      variant: 'primary',
      width: 'min',
      shadow: 'none',
      disabled: false,
      hasChildren: false,
    },
  }
);

interface LinkProps {
  href?: AnchorHTMLAttributes<HTMLAnchorElement>['href'];
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  download?: AnchorHTMLAttributes<HTMLAnchorElement>['download'];
  prefetch?: boolean;
}

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'disabled' | 'color'>,
    LinkProps,
    VariantProps<typeof ButtonStyled> {
  children?: ReactNode;
  iconBefore?: IconType;
  iconAfter?: IconType;
  as?: ElementType;
  'aria-label'?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  ref?: Ref<HTMLButtonElement | HTMLAnchorElement>;
}

export const Button = ({
  as: Component = 'button',
  className,
  children,
  href,
  target,
  prefetch = false,
  iconBefore = null,
  iconAfter = null,
  type = 'button',
  color = 'red',
  size = 'md',
  variant = 'primary',
  width = 'min',
  shadow = 'none',
  disabled = false,
  'aria-label': ariaLabel,
  ref,
  ...props
}: ButtonProps) => {
  const hasChildren = !!children;
  const isButton = Component === 'button';

  const buttonClassName = ButtonStyled({
    color,
    size,
    variant,
    width,
    shadow,
    disabled,
    hasChildren,
    className,
  });

  const ariaLabelValue = ariaLabel || (children ? undefined : 'Button');

  const elementProps = href
    ? {
        href,
        target,
        rel: target === '_blank' ? 'noopener noreferrer' : undefined,
        prefetch,
      }
    : {
        type: isButton ? type : undefined,
        disabled: isButton ? disabled : undefined,
      };

  const content = (
    <>
      {renderIcon(iconBefore, size, hasChildren)}
      {!!children && children}
      {renderIcon(iconAfter, size, hasChildren)}
    </>
  );

  const Element = href ? Link : Component;

  return (
    <Element
      ref={ref as Ref<HTMLAnchorElement | HTMLButtonElement>}
      className={buttonClassName}
      aria-label={ariaLabelValue}
      {...elementProps}
      {...props}
    >
      {content}
    </Element>
  );
};

import type { VariantProps } from 'class-variance-authority';
import { ReactElement } from 'react';

import { cnva } from '@/app/utils/cnva';

export const IconStyled = cnva(['relative block fill-current'], {
  variants: {
    size: {
      xs: 'size-3',
      sm: 'size-4',
      md: 'size-6',
      lg: 'size-8',
      xl: 'size-10',
      '2xl': 'size-12',
      '3xl': 'size-14',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export type IconType = ReactElement | null;

export interface IconProps extends VariantProps<typeof IconStyled> {
  className?: string;
  icon?: IconType;
}

export const Icon = ({ className, icon, size }: IconProps) => {
  return (
    <i aria-hidden="true" className={IconStyled({ size, className })}>
      {icon}
    </i>
  );
};

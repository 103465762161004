import type { ReactNode } from 'react';

import { Container } from '@/app/components/layouts/container';
import { cn } from '@/app/utils/cn';

export interface AIFooterProps {
  children: ReactNode;
  className?: string;
}

export const AIFooter = ({ children, className }: AIFooterProps) => {
  return (
    <footer className={cn('w-full pt-4 pb-10', className)}>
      <Container maxWidth="sm">{children}</Container>
    </footer>
  );
};

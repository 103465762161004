import type { ReactNode } from 'react';

import { ContainerStyled } from '@/app/components/layouts/container';
import { cn } from '@/app/utils/cn';

export interface AIWrapperProps {
  children: ReactNode;
  className?: string;
}

export const AIWrapper = ({ children, className }: AIWrapperProps) => {
  return (
    <div
      className={cn(
        'relative flex h-full flex-col',
        ContainerStyled({
          size: 'none',
          maxWidth: 'sm',
        }),
        className
      )}
    >
      {children}
    </div>
  );
};

import type { VariantProps } from 'class-variance-authority';
import type { ReactNode } from 'react';

import { cnva } from '@/app/utils/cnva';

export const ContainerStyled = cnva('w-full', {
  variants: {
    size: {
      sm: 'px-4 md:px-10',
      md: 'px-4 md:px-10 xl:px-13',
      'mobile-only': 'px-4 md:px-10 xl:px-0',
      'desktop-only': 'xl:px-13',
      none: '',
    },
    maxWidth: {
      sm: 'mx-auto max-w-160',
      md: 'mx-auto max-w-192',
      lg: 'mx-auto max-w-256',
      xl: 'mx-auto max-w-320',
      none: '',
    },
  },
  defaultVariants: {
    size: 'md',
    maxWidth: 'xl',
  },
});

interface ContainerProps extends VariantProps<typeof ContainerStyled> {
  children: ReactNode;
  className?: string;
}

export const Container = ({
  children,
  className,
  size = 'md',
  maxWidth = 'xl',
  ...rest
}: ContainerProps) => {
  return (
    <div
      className={ContainerStyled({
        size,
        maxWidth,
        className,
      })}
      {...rest}
    >
      {children}
    </div>
  );
};

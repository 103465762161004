import { extendTailwindMerge } from 'tailwind-merge';

const FONT_SIZES = [
  'text-display',
  'text-h1',
  'text-h2',
  'text-h3',
  'text-sub-h1',
  'text-sub-h2',
  'text-sub-h3',
  'text-body-1',
  'text-body-2',
  'text-body-1-fixed',
  'text-body-2-fixed',
  'text-caption',
  'text-button-1',
  'text-button-2',
];

const BACKGROUND_COLORS = [
  'bg-inherit',
  'bg-current',
  'bg-white',
  'bg-black',
  'bg-transparent',
  'bg-transparent-30',
  'bg-transparent-50',
  'bg-transparent-70',
  'bg-gray-50',
  'bg-gray-100',
  'bg-gray-200',
  'bg-gray-300',
  'bg-gray-400',
  'bg-gray-500',
  'bg-gray-600',
  'bg-gray-700',
  'bg-gray-800',
  'bg-gray-900',
  'bg-red-50',
  'bg-red-100',
  'bg-red-200',
  'bg-red-300',
  'bg-red-400',
  'bg-red-500',
  'bg-red-600',
  'bg-red-700',
  'bg-red-800',
  'bg-red-900',
  'bg-azure-50',
  'bg-azure-100',
  'bg-azure-200',
  'bg-azure-300',
  'bg-azure-400',
  'bg-azure-500',
  'bg-azure-600',
  'bg-azure-700',
  'bg-azure-800',
  'bg-azure-900',
  'bg-brown-50',
  'bg-brown-100',
  'bg-brown-200',
  'bg-brown-300',
  'bg-brown-400',
  'bg-brown-500',
  'bg-brown-600',
  'bg-brown-700',
  'bg-brown-800',
  'bg-brown-900',
  'bg-green-50',
  'bg-green-100',
  'bg-green-200',
  'bg-green-300',
  'bg-green-400',
  'bg-green-500',
  'bg-green-600',
  'bg-green-700',
  'bg-green-800',
  'bg-green-900',
  'bg-blue-50',
  'bg-blue-100',
  'bg-blue-200',
  'bg-blue-300',
  'bg-blue-400',
  'bg-blue-500',
  'bg-blue-600',
  'bg-blue-700',
  'bg-blue-800',
  'bg-blue-900',
  'bg-crimson-50',
  'bg-crimson-100',
  'bg-crimson-200',
  'bg-crimson-300',
  'bg-crimson-400',
  'bg-crimson-500',
  'bg-crimson-600',
  'bg-crimson-700',
  'bg-crimson-800',
  'bg-crimson-900',
];

const PADDING_CLASSES = [
  'p-0',
  'p-px',
  'p-0.5',
  'p-1',
  'p-1.5',
  'p-2',
  'p-2.5',
  'p-3',
  'p-3.5',
  'p-4',
  'p-5',
  'p-6',
  'p-7',
  'p-8',
  'p-9',
  'p-10',
  'p-11',
  'p-12',
  'p-14',
  'p-16',
  'p-20',
  'p-24',
  'p-28',
  'p-32',
  'p-36',
  'p-40',
  'p-44',
  'p-48',
  'p-52',
  'p-56',
  'p-60',
  'p-64',
  'p-72',
  'p-80',
  'p-96',
];

const config = {
  extend: {
    classGroups: {
      'font-size': FONT_SIZES,
    },
    conflictingClassGroups: {
      'font-size': [],
    },
  },
};

export const customTwMerge = extendTailwindMerge(config);
